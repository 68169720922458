import React from 'react';
import '../../css/MapSection.css'; 
import { WindowContext } from '../../WindowContext';


export const MapSection = () => {
  const { widthWindow } = React.useContext(WindowContext);
  return (
    <section className='mapContainer'>

        <h3>¿Dónde nos ubicamos?</h3>
        <div>
        <iframe 
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d513.7465266510493!2d-71.97738570825855!3d-13.517345735140964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x916dd6738ba833a3%3A0x6da60069176e02f7!2sGenesis%20bookstore!5e0!3m2!1ses-419!2spe!4v1681081131968!5m2!1ses-419!2spe'

            width= {widthWindow >=640 ? "500": `${widthWindow-120}`}
            height= {widthWindow >=640 ? "375": `${widthWindow-170}`}
            style={{border: 0}}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title='genesis_map'
        />
        </div>
    </section>
  )
}
