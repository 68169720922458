import React from 'react'

function AvailableDiv({item, marginTop}) {
  return (
    <p 
    className='availableLabel'
    style={{
        color: `${(item.isAvailable)?"#f3b868ff":"red"}`,
        border: `1px solid ${(item.isAvailable)?"#f3b868ff":"red"}`,
        borderRadius: 4,
        fontWeight: 500,
        display: 'inline-block',
        marginTop: marginTop,
        width: "fit-content",
        height: "fit-content",
        padding: 5,
    }}
    >
        {(item.isAvailable)?"Disponible":"No disponible"}
    </p>
  )
}

export {AvailableDiv}