import React, { useState, useEffect } from 'react'

const useApi = (delay, apiURL) => {
    const [items_, setItems] = useState([]);
    const [doSearch, setDoSearch] = React.useState(false);

    const fetchApi = async () => {
        try{
          if(!!apiURL){
            const response = await fetch(apiURL, { method: "GET" }); 
            // console.log(response.status);
            if(response.status===400){
              console.log("Hubo un error")
                // (setError(true));
                }
            else{
            const responseJSON = await response.json();
            const listedProducts = responseJSON;
            // (!!!listedProducts.data) ? console.log("Si llega  a primera parte") : console.log("Si llega a segunda parte")
            (!!!listedProducts.data) ? ((!!listedProducts.length) ? setItems([...listedProducts]) : setItems([])) : setItems([...listedProducts.data]);
            setDoSearch(false);
            }
          }
        }
        catch(error){
          console.log("Entra a la parte de error");
          console.log(error);
          console.log("Hubo un error")
          // setError(true);
        }
    }

  useEffect( () => {
    if(doSearch){
      setTimeout( fetchApi, delay)
    };
  }, [doSearch]);


  return {items_, doSearch, setDoSearch, setItems};

}

export {useApi}
