import { HeaderWeb } from '../HeaderWeb';
import { FooterSection } from '../FooterSection';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { HomePage } from './HomePage';
import { SearchPage } from './SearchPage';
import { Modal } from '../Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonThroughWindow } from '@fortawesome/free-solid-svg-icons';
import '../css/App.css';

function App() {
  return (
    <>
    <HashRouter>
      <HeaderWeb/>
      <Routes>
        <Route path='/' element={<HomePage/>}/>
        <Route path='/search/:target' element={<SearchPage/>}/>
        <Route path='*' element={<div style={{textAlign:"center", margin: "0 auto", backgroundColor: "#201e1c"}}><FontAwesomeIcon icon={faPersonThroughWindow} size="10x" style={{color: "#FFD43B",}} /><p style={{color: "#FFD43B"}}>Page not found</p></div>}/>
      </Routes>
      <FooterSection/>
    </HashRouter>
    <Modal/>
    </>
  );
}

export default App;
