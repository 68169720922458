import React from 'react';
import '../../css/App.css';
import '../../css/Cards.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';

export const CategoryCards
 = ({topicList}) => {
  return (
    <>
    <section className='TopicSection'><h2>Los temas mas solicitados <br/> por  nuestros clientes</h2>
    <div className='fixingIcon'>
        {/* <FontAwesomeIcon icon={faScrewdriverWrench} size='4x' style={{color: "#f3b868ff"}} /> */}
    </div>
      <section className='cardsContainer'>
      {topicList.map((item, index)=>(
        <div className='topicsCard' key={`${index}`}>
          <span style={{backgroundImage: `url(${item.link})`}} className='icon_config'></span>
          <p>{item.text}</p>
          <p></p>
        </div>
      ))}
      </section>
    </section>
  </>
  )
}
