// import React, {useEffect, useState} from 'react'
import { SearchResults } from './SearchResults';
import { WaitSearch } from './WaitSearch';
import { EmptySearch } from './EmptySearch';


export const SearchSection = ({searchText, currentProducts: currentSearch, loading}) => {

    return (
    <section id='search-bar' className='search-container'>
        {(!!searchText && !!loading) && <WaitSearch/>}
        {(!currentSearch.length && !loading && !!searchText) && <EmptySearch/>}
        {(!!currentSearch.length && !loading && !!searchText) && <SearchResults currentSearch={currentSearch} searchText={searchText}/>}
    </section>   
    )
}
