import { createContext, useState } from "react";
import { useParallaxBanner } from "../Routes/useParallaxBanner";

const WindowContext = createContext();

const WindowProvider = ({children}) => {
    
    const [scrollPosition, setScrollPosition] = useState(0);
    const [submitText, setSubmitText] = useState('')
    
    useParallaxBanner({setScrollPosition});

    return (
        <WindowContext.Provider value={{
            scrollPosition,
            submitText,
            setSubmitText,
        }}>
            {children}
        </WindowContext.Provider>
    )
}

export {WindowContext, WindowProvider}