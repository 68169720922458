import React, { useContext, useEffect } from 'react'
import { useSearchParams, useLocation, useParams } from 'react-router-dom';
import { SearchSection } from '../../SearchSection';
import { useDoSearch } from '../../customHook/useDoSearch';
import { WindowContext } from '../../WindowContext';

export const SearchPage = () => {
    const { target } = useParams();
    const { states, updaters } = useDoSearch();
    const [ setSearchText ] = updaters;
    const [ searchText, currentProducts, loading ] = states;

    //esta parte maneja el zoom del cover
    const {
    submitText,
    } = useContext(WindowContext);
    
    //esta logica es para capturar el evento cuando cambia de link a /search?target=xxx
    useEffect(()=>{
      if(!!target){
        setSearchText(submitText);
        } 
    }, [submitText]);

  return (
    <SearchSection 
      searchText={searchText}
      currentProducts={currentProducts}
      loading={loading}
      />
  )
}
