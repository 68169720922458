import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay} from 'swiper';
import {Text} from 'react-native';
import SkeletonCard from './SkeletonCard'
import { AvailableDiv } from '../SearchSection/AvailableDiv';

function SliderCardsUI({
    links,
    numberSlides,
    offsetSlides,
    }) {

  return (
    <section className='swiperContainer'>
    <h2 className='novedades_title'>Novedades: <span style={{
      display: "inline-block",
      fontSize: 18,
      color: "#dddddd" }}>[Humanidades]</span>
    </h2>
    {!links.length && <SkeletonCard numberSlides={numberSlides} offsetSlides={offsetSlides}/>}
    {
      !!links.length && (
        <Swiper
        modules={[Navigation, Autoplay]}
        slidesPerView={numberSlides}
        slidesOffsetBefore={offsetSlides}
        autoplay = { {  delay: 2500,
                  disableOnInteraction: false,
                } }
        loop = {true}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        {
        links.map((item,index) => (
          <SwiperSlide key={`${item[0]}-${index}`}>
            <div className='productContainer'>
              <div style={{backgroundColor: `${ (((item[1].imgHeight)*(145/item[1].imgWidth) < 190) && "#FFFF") || ("transparent")}`, height: 200, width: 145}}>
              <img 
                style={{objectFit: `${ (((item[1].imgHeight)*(145/item[1].imgWidth) < 190) && "contain") || ("fill")}`}}
                src = {item[1].imgURL}
                alt='Imagen de libro'
                // loading='lazy'
                />
              </div>
              <Text numberOfLines={2} style={{width: "95%", height: 35, color:'rgb(178,178,178)', textAlign:'left', fontWeight: 500, paddingLeft: 10}}>
                {item[1].title}
              </Text>
              <Text numberOfLines={2} style={{width: "100%", height: 35, color:'rgb(148,148,148)', textAlign:'left', fontWeight: 300, fontSize: 14, paddingLeft: 10, paddingRight: 10}}>
                {item[1].autor}
              </Text>
              {console.log(item[1])}
              <h3 style={{fontSize: 18, color: "#FFFF", fontWeight:500, height: 24}}>{`s/ ${item[1].pv.toFixed(2)}`.replace('.', ',')}</h3>
              <div style={{display: "flex", alignItems: "center", gap: 5}}>
                <AvailableDiv item={{isAvailable: item[1].isAvailable}} marginTop={5}/>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      )
    }
    </section>
  )
}

export default SliderCardsUI