import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { MapSection } from './MapSection'
import { CategoryCards } from './CategoryCards'
import '../css/FooterSection.css'

function FooterSectionUI({CategoryTopics: topics}) {
  return (
    <>
    <CategoryCards topicList = {topics}/>
    <MapSection/>
    <div className='footerContainer'>
        <div>
        <FontAwesomeIcon className='socialIcon' icon={faFacebook} size='2xl' style={{color: "#FFFF"}}/>
        <a href='https://www.facebook.com/libreriagenesiscusco' target='_blank' rel='noreferrer'>Genesis - Facebook</a>
        </div>
        <div>
        <FontAwesomeIcon className='socialIcon' icon={faInstagram} size='2xl' style={{color: "#FFFF"}}/>
        <a href='https://www.instagram.com/libreriagenesiscusco/' target='_blank' rel='noreferrer'>Genesis - Instagram</a>
        </div>
        <div>
        <FontAwesomeIcon className='socialIcon' icon={faWhatsapp} size='2xl' style={{color: "#FFFF"}}/>
        <a href='https://wa.me/51935017677' target='_blank' rel='noreferrer'>Genesis - Whatsapp</a>
        </div>
    </div>
    </>
  )
}

export default FooterSectionUI