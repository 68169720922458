import React from 'react';
import '../../css/WaitSearch.css';

export const WaitSearch = () => {
  return (
    <div className='waitSearch_container'>  
        <div className="container">
        <span/>
            <div className="center">
                <div className="wrap">
                    <div className="box-1 box">
                        <i/>
                        <i/>
                    </div>
                    <div className="box-2 box">
                        <i/>
                        <i/>
                    </div>
                </div>
            </div>
        </div>
        <h3>Buscando...</h3>
    </div>
  )
}
