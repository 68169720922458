import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsappSquare } from '@fortawesome/free-brands-svg-icons';
import { WindowContext } from '../WindowContext';

//esto maneja el icono de whatsapp que va animado a la esquina de la ventana

export const Modal = () => {

    const {widthWindow} = React.useContext(WindowContext);

    return (
        <div className={widthWindow>500 ? "modal" : "modal_small"}>
            <a href='https://wa.me/51935017677' target='_blank' rel="noopener noreferrer">
                <FontAwesomeIcon icon={faWhatsappSquare} bounce size={widthWindow > 500 ? "7x" : "6x"} style={{color: "#25d366",}} />
            </a>
        </div>
    )
}
