import React, { useEffect } from 'react';
// import { WindowContext } from '../WindowContext';
import { useApi } from '../customHook/useApi';
import '../css/SliderCards.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import SliderCardsUI from './SliderCardsUI';
import useWdtWindow from './useWdtWindow';

let numberSlides = 2;
let offsetSlides;

export const SliderCards = () => {

  const [linksObj, setLinksObj] = React.useState({})
  const [wdtWindow, setWdtWindow] = React.useState(window.outerWidth);
  useWdtWindow({setWdtWindow})
  const {
    items_: items,
    setDoSearch,
    setItems,
   } = useApi(
        1000,
        "https://nodejs-mysql-restapi-production-5162.up.railway.app/api/products?samples=12&category=humanidades");
        // esto debe tener dependencias
  useEffect(()=>{
    setDoSearch(true)
  }, [])

  const getSlidesNumber = () => {
    if(wdtWindow <= 350){return 1}
    else if(wdtWindow > 350 && wdtWindow <= 540){return 2}
    else if((wdtWindow > 540 && wdtWindow <= 780)){return 3}
    else if(wdtWindow > 780 && wdtWindow <= 900){ return 4}
    else if(wdtWindow > 900 && wdtWindow <= 1080){return 5}
    else{return 6}
  }
  
  numberSlides = getSlidesNumber();
  offsetSlides = (wdtWindow - (165 * numberSlides))/(2 * numberSlides);

  const getLinks = () => {
    let linksObj_ = {...linksObj};
    [...items].forEach((item, index)=>{

      let autorWords = item.autor.toLowerCase().split(" ");
      for (let i = 0; i < autorWords.length; i++) {
        autorWords[i] = autorWords[i][0].toUpperCase() + autorWords[i].substr(1);
      }
      let autor_ = autorWords.join(" ");

      linksObj_[item.code.toLowerCase()] = 
      {
      imgURL: `https://genesiscuscobucket.s3.amazonaws.com/${item.code.toLowerCase()}.webp`,
      imgHeight: 0,
      imgWidth: 0,
      title: item.title,
      autor: autor_,
      publisher: item.editorial,
      pv: item.pv,
      isAvailable: item.isAvailable
      }
    }
  )
    setLinksObj(linksObj_)
    return linksObj_
  }
  
  const fillWidthImage = (items_) => {

    [...items].forEach((item, index)=>{
      let img = new Image();
      img.src = `https://genesiscuscobucket.s3.amazonaws.com/${item.code.toLowerCase()}.webp`;
      img.onload = () =>  {
        let tmpLinks = {...items_}
        if(!!tmpLinks){
          tmpLinks[item.code.toLowerCase()]["imgHeight"] = img.height
          tmpLinks[item.code.toLowerCase()]["imgWidth"] = img.width
          setLinksObj(tmpLinks)
          }
        }
      }
    )
  }

  if (items !== undefined && !!items.length){
    let linksObj = getLinks();
    fillWidthImage(linksObj);
    setItems([])
  }


  return (
    <SliderCardsUI
      links = {Object.keys(linksObj).map((key) => [key, linksObj[key]])}
      numberSlides = {numberSlides}
      offsetSlides = {offsetSlides}
    />
  )
}
