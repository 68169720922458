// import React from 'react'
// import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Skeleton from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";
import '../../css/SliderCards.css'


const SkeletonItem = () => {
  return (
        <div className='card-skeleton'>
          <div><Skeleton duration={2} height={200} width={145} baseColor='#313131' highlightColor='#525252' /></div>
          <div><Skeleton duration={2} height={35} width={145} baseColor='#313131' highlightColor='#525252' /></div>
          <div><Skeleton duration={2} height={35} width={145} baseColor='#313131' highlightColor='#525252' /></div>
          <div><Skeleton duration={2} height={24} width={145} baseColor='#313131' highlightColor='#525252' /></div>
          <div><Skeleton duration={2} height={29} width={145} baseColor='#313131' highlightColor='#525252' /></div>
        </div>
  )
}


const SkeletonCard = (props) => {
  return (
    <section className='section-skeleton' style={{ paddingLeft: props.offsetSlides, paddingRight: props.offsetSlides}}>
    {Array(props.numberSlides).fill(0).map(
      (data, index)=>(
        <SkeletonItem key={index}/>
      )
    )}
    </section>
  );
};

export default SkeletonCard



