import React from 'react'
import { SliderCards } from '../../SliderCards'


export const HomePage = () => {
  return (
    <>
        <SliderCards/>
    </>
  )
}
