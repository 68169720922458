import { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams} from 'react-router-dom';
import { WindowContext } from '../WindowContext';
import HeaderWebUI from './HeaderWebUI';
import '../css/HeaderWeb.css';
import '../css/App.css';

export const HeaderWeb = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchText, setSearchText] = useState('')
    
  //esta parte maneja el zoom del cover
  const {
    scrollPosition,
    submitText,
    setSubmitText,
    } = useContext(WindowContext);

  useEffect(()=>{
    setSearchText(location.pathname.split('/')[2]?.replaceAll('%20',' '));
    navigateSearch(location.pathname.split('/')[2]?.replaceAll('%20',' '));
  },[])


  // Esta funcion quita del input las tildes, mayusculas, etc
  const normalizeString = (string_) => {
      return (string_.normalize("NFD").replace(/[\u0300-\u036f]/g, "")).toLowerCase();
  }

  const navigateSearch = (value) => {
    if(!!value){
        let currentSearchText = normalizeString(value);
        !!currentSearchText && (navigate(`/search/${currentSearchText}`));
        setSubmitText(currentSearchText);
    }
  }

  const onSubmit = (event) => {
    event.preventDefault();
    navigateSearch(searchText);
  }

  return (
    <HeaderWebUI 
      scrollPosition={scrollPosition}
      navigate={navigate}
      onSubmit={onSubmit}
      searchText={searchText}
      setSearchText={setSearchText}
      />
  )
}
