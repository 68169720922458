import React from 'react'
import { useMediaQuery } from 'react-responsive';

function HeaderWebUI({
    scrollPosition,
    navigate,
    onSubmit,
    searchText,
    setSearchText}) {

    const minWidthWindow = useMediaQuery({
    query: '(min-width: 690px)'
    })
  
    return (
    <>
        <section
        style={minWidthWindow 
            ?{  backgroundSize: `${100 + scrollPosition*0.15}%`,
                height: `${46.1}vh`
            }
            :{  backgroundSize: "cover",
                height: `${46.1}vw`
            }}
        className='banner'
        >
        <div className='glass'></div>
        <div className='logo_container'>
            <img
            src='https://i.imgur.com/5jlOTx9.png'
            className='logo_'
            alt='logo de genesis'
            onClick={()=>{navigate('/')}}/>
        </div>
        <div className='header__button'>Utiliza nuestro buscador<span></span></div>
        </section>
        <form  className='search-bar' onSubmit={onSubmit}>
        <input
            id='hola'
            type='search'
            placeholder='Titulo o autor'
            value={searchText}
            onChange={(e)=>{setSearchText(e.target.value)}}
        />
        <button type='submit'><i className='fa fa-search fa-2x' style={{color: "white",}}/></button>
        </form>
    </>
  );
}

export default HeaderWebUI