import React from 'react';
import '../../../css/Result.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import  imageExists from 'image-exists';
import {AvailableDiv} from '../../AvailableDiv';

const ROOT_URL = "https://genesiscuscobucket.s3.amazonaws.com/";

export const Result = ({item}) => {
    const [URL, setURL] = React.useState('')

    React.useEffect(()=>{
        imageExists(`${ROOT_URL+item.code.toLowerCase()}.webp`,(img) => {
            if(img){
                setURL(`${ROOT_URL+item.code.toLowerCase()}.webp`);
            }
        })
    },[]);
  
    return (
    <li className='list_results'>
        <div className='resultContainer'>
            { (!!URL)? <img 
                className='imgResult' 
                src={URL}
                loading='lazy'
                /> : <FontAwesomeIcon icon={faBook} size='2xl' style={{fontSize: 70, color: "#f3b868ff"}}/>}
            <div className='resultText'>
                <h3>{item.title}</h3>
                <p className='pAutor'>{item.autor}</p>
                <p className='pEditorial'>{item.editorial}</p>
                <p style={{display: 'block', marginTop: 5}}>{`S/.${item.pv}`}</p>
                <AvailableDiv item={item} marginTop={10}/>
            </div>
        </div>
    </li>
    )
}
