import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBook } from '@fortawesome/free-solid-svg-icons';
import '../../css/EmptySearch.css';

export const EmptySearch = () => {
  return (
    <>
    {/* <FontAwesomeIcon icon={faSearch} size="5x" /> */}
    <div className='emptySearch_container'>
      <div className="wrap">
        <i className="fa fa-search search-1"></i>
        <i className="fa fa-search search-2"></i>
        <i className="fa fa-search search-3"></i>
        <i className="fa fa-search search-4"></i>
        <div className="items">
          <i className="fa fa-book"></i>
          <i className="fa fa-book"></i>
          <i className="fa fa-book"></i>
          <i className="fa fa-book"></i>
          <i className="fa fa-book"></i>
          <i className="fa fa-book"></i>
          <i className="fa fa-book"></i>
          <i className="fa fa-book"></i>
          <i className="fa fa-book"></i>
          <i className="fa fa-book"></i>
        </div>
      </div>
      <h3 className='txtResults'>Sin resultados</h3>
    </div>
    </>
  )
}
