import React from 'react';
import { Result } from './Result';

export const SearchResults = ({currentSearch, searchText}) => {
  return (
    <>

        <div className='textResultsContainer'>
          <hr/>
          <p className='textResults'>Encontramos <b>{currentSearch.length}</b> {(currentSearch.length > 1) ? " concidencias": "coincidencia"} para <b>{searchText}</b>.
          </p>
          <hr/>
        </div>

        <section>
            <ul className='resultsContainer'>
              {currentSearch.map((item, index)=>(
                <Result item={item} key={`${index}`}></Result>
              ))}
            </ul>
        </section>
    </>
  )
}
