import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './Routes/App';
import { WindowProvider } from './WindowContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <WindowProvider>
      <App />
    </WindowProvider>
  </>
);
