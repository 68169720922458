import { useEffect, useState } from 'react';

export const useDoSearch = () => {
    const [searchText, setSearchText] = useState('');
    const [items, setItems] = useState([]);
    const  [loading, setLoading] = useState(false);

    const apiURL = `https://nodejs-mysql-restapi-production-5162.up.railway.app/api/products/${searchText}`

    const doFetch = async () =>{
        try{
            if(!!apiURL){
                // setLoading(true);
                const response = await fetch(apiURL, { method: "GET" }); 
                if(response.status!==200){
                console.log("status code difetent than 200");
            }
            else{
                const responseJSON = await response.json();
                const listedProducts = await responseJSON;
                (!!!listedProducts.data) ? ((!!listedProducts.length) ? setItems([...listedProducts]) : setItems([])) : setItems([...listedProducts.data]);
                }
            }
            setLoading(false);
          }
          catch(error){
            console.log(`An error ocurred: ${error}`);
          }
    }   
    
    useEffect( () => {
        if(!!searchText){
            setLoading(true);
            setTimeout(doFetch, 500);
        }
    }, [searchText]);

    
    const states = [
        searchText,
        items,
        loading,
    ]

    const updaters = [
        setSearchText,
    ]

    return { states, updaters}
}
