import { useEffect } from "react";

export const useParallaxBanner = ({setScrollPosition}) =>{ 
    const handleScroll = () => setScrollPosition(window.pageYOffset);

    useEffect(()=> {
        window.addEventListener("scroll", handleScroll, { passive: true});
        return ()=>(window.removeEventListener("scroll", handleScroll));
    }, []);
}
