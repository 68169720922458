import React from 'react'
import FooterSectionUI from './FooterSectionUI'

const topics = [  {text:"Historia",
                    link: "https://i.imgur.com/DHVH3Jg.png",},
                  {text:"Turismo",
                  link: "https://i.imgur.com/gtPMINb.png",},
                  {text:"Humanidades",
                  link: "https://i.imgur.com/LGSCjyX.png",},
                  {text: "Literatura clásica",
                   link: "https://i.imgur.com/dsQglhA.png",},
                  {text: "Literatura contemporánea",
                   link: "https://i.imgur.com/7zBkSmB.png",},
                  {text: "Superación personal",
                    link: "https://i.imgur.com/CrqFVvw.png",},
                  {text: "Gatronomía peruana",
                  link: "https://i.imgur.com/vZIwEzq.png",},
                  {text: "Idiomas",
                  link: "https://i.imgur.com/uuEyLbU.png",}
                ]

export const FooterSection = () => {
  return (
    <FooterSectionUI CategoryTopics = {topics}/>
  )
}
