import { useEffect } from 'react'

function useWdtWindow({setWdtWindow}) {
    const handleSize = () => setWdtWindow(window.outerWidth);
    useEffect(()=> {
        window.addEventListener("resize", handleSize, { passive: true});
        return ()=>(window.removeEventListener("resize", handleSize));
    }, []);
}

export default useWdtWindow